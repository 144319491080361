<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <div class="data-grid">
        <dx-data-grid
          ref="refRawMaterialGrid"
          :data-source="rawMaterialInfoList"
          :show-borders="true"
          :show-row-lines="false"
          :show-column-lines="true"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :allow-column-reordering="true"
          :height="gridHeight"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :auto-navigate-to-focused-row="false"
          key-expr="품번"
        >
          <DxSearchPanel
            :visible="true"
            :width="150"
            placeholder="검색..."
          />
          <DxColumnFixing :enabled="true" />
          <DxColumn
            v-if="$store.getters.getGroupName !== null"
            :allow-editing="false"
            :caption="$store.getters.getGroupName"
            data-field="차종"
            sort-order="asc"
          />
          <DxColumn
            :allow-editing="true"
            caption="품번"
            data-field="품번"
            :fixed="false"
            sort-order="asc"
            :width="columnWidth[0]"
          />
          <DxColumn
            :allow-editing="true"
            caption="현재공"
            data-field="현재공"
            :visible="true"
            format="#,##0"
            :calculate-cell-value="calcStock"
            :min-width="columnWidth[1]"
          />
          <DxColumn
            v-if="goodsType !== 'S0106'"
            :allow-editing="true"
            caption="월생산"
            data-field="생산실적"
            :visible="true"
            format="#,##0"
            :min-width="columnWidth[2]"
          />
          <DxColumn
            v-if="goodsType === 'S0106'"
            :allow-editing="true"
            caption="월입고"
            data-field="입고수량"
            :visible="true"
            format="#,##0"
            :min-width="columnWidth[2]"
          />
          <DxColumn
            :allow-editing="true"
            caption="월출하"
            data-field="출고수량"
            :visible="true"
            format="#,##0"
            :min-width="columnWidth[3]"
          />
          <DxColumn
            :allow-editing="true"
            caption="월반품"
            data-field="반품수량"
            :visible="true"
            format="#,##0"
            :min-width="columnWidth[4]"
          />
          <DxColumn
            :allow-editing="true"
            caption="품명"
            data-field="품명"
            :visible="true"
            :min-width="columnWidth[5]"
          />
          <dx-paging :enabled="false" />
          <dx-scrolling
            mode="virtual"
          />
          <dx-sorting mode="multiple" />
        </dx-data-grid>
      </div>
      <loading
        :active.sync="isLoading"
        color="rgb(2, 110, 156)"
        loader="bars"
      />
    </v-container>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import DxDataGrid, {
  DxSearchPanel,
  DxScrolling, DxColumnFixing,
  DxColumn, DxPaging, DxSorting
} from 'devextreme-vue/data-grid'

export default {
  name: 'CurrentStockComponent',
  components: {
    Loading,
    DxDataGrid,
    DxScrolling,
    DxColumnFixing,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSearchPanel
  },
  props: {
    goodsType: {
      type: String,
      default: 'S0100'
    }
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      gridHeight: null,
      rawMaterialInfoList: null,
      columnWidth: [140, 70, 70, 70, 70, 150]
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.refreshCurrentStock()
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
  },
  methods: {
    calcStock (row) {
      return row.현재공 + row.창고재고
    },
    handleResize () {
      this.gridHeight = (this.$Q('html').height() - 76)
    },
    refreshCurrentStock () {
      this.stockTitle = `${AppLib.getToday()}_완제품_창고재고`
      const param = {
        toDate: AppLib.calcDateFromToday(1),
        품목유형: this.goodsType,
        IsTest: false
      }
      this.isLoading = true
      this.$_sp.runNoEncodeFindProc('spFindAllStockReceiptByToDateAndPtypeV2', param)
        .then((data) => {
          this.isLoading = false
          this.rawMaterialInfoList = this.$_sp.MakeModel(data)
          console.log(this.rawMaterialInfoList)
          setTimeout(() => {
            this.$refs.refRawMaterialGrid.instance.refresh()
          }, 600)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">

</style>
